var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"900px","fullscreen":_vm.$vuetify.breakpoint.xsOnly,"content-class":"rounded-xl"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"outlined":_vm.$vuetify.theme.dark,"rounded":"xl"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"text-h6"},[_vm._v("Ledger")]),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.modalClosed}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"height":"460px","fixed-header":"","items-per-page":_vm.page_count,"server-items-length":_vm.page_count,"loading":_vm.loading,"loading-text":"Please wait.... Loading","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","mobile-breakpoint":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.formatDate(item.datetime))+" ")]}},{key:"item.case",fn:function(ref){
var item = ref.item;
return [(item.case)?_c('div',[_vm._v(" "+_vm._s(item.case.case_id)+" ")]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.credit",fn:function(ref){
var item = ref.item;
return [(item.credit_amount)?_c('div',{staticClass:"green--text font-weight-bold"},[_vm._v(" +"+_vm._s(item.credit_amount)+" ")]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.debit",fn:function(ref){
var item = ref.item;
return [(item.debit_amount)?_c('div',{staticClass:"red--text font-weight-bold"},[_vm._v(" -"+_vm._s(item.debit_amount)+" ")]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [(item.balance_amount)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.balance_amount)+" ")]):_c('div',[_vm._v("0")])]}}],null,true)}),_c('v-divider'),(_vm.page_number < _vm.page_total)?_c('div',{staticClass:"my-4 d-flex justify-center"},[_c('v-btn',{staticClass:"rounded-lg",attrs:{"small":"","color":"primary"},on:{"click":_vm.loadMore}},[_vm._v("Load More")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }