export default [
  {
    label: `Agent Ledger`,
    key: "agent_ledger",
    value: 0,
  },
  {
    label: `University Ledger`,
    key: "university_ledger",
    value: 0,
  },
];
