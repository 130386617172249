var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-3 rounded-t-0",attrs:{"items-per-page":_vm.page_count,"server-items-length":_vm.page_count,"loading":_vm.loading,"loading-text":"Please wait.... Loading","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","mobile-breakpoint":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.formatDate(item.datetime))+" ")]}},{key:"item.case",fn:function(ref){
var item = ref.item;
return [(item.case)?_c('div',[_vm._v(" "+_vm._s(item.case.case_id)+" ")]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.credit",fn:function(ref){
var item = ref.item;
return [(item.credit_amount)?_c('div',{staticClass:"green--text font-weight-bold"},[_vm._v(" +"+_vm._s(item.credit_amount)+" ")]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.debit",fn:function(ref){
var item = ref.item;
return [(item.debit_amount)?_c('div',{staticClass:"red--text font-weight-bold"},[_vm._v(" -"+_vm._s(item.debit_amount)+" ")]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [(item.balance_amount)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.balance_amount)+" ")]):_c('div',[_vm._v("0")])]}}],null,true)}),_c('v-container',{staticClass:"d-flex justify-space-between align-center px-0 mb-0 mt-4",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex align-center text-subtitle-2"},[_c('div',{staticClass:"mr-2"},[_vm._v("Rows per page")]),_c('div',{staticStyle:{"width":"75px"}},[_c('v-select',{staticClass:"rounded-lg",attrs:{"menu-props":{ bottom: true, offsetY: true },"items":_vm.itemsPerPageList,"hide-details":"","dense":"","solo":"","color":"primary"},model:{value:(_vm.page_count),callback:function ($$v) {_vm.page_count=$$v},expression:"page_count"}})],1),_c('div',{staticClass:"ml-6"},[_vm._v(" Results: "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageEnd)+" of "+_vm._s(_vm.total_count)+" ")])]),_c('v-pagination',{attrs:{"length":_vm.page_total,"total-visible":5,"color":"primary"},model:{value:(_vm.page_number),callback:function ($$v) {_vm.page_number=$$v},expression:"page_number"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }