<template>
  <div>
    <div v-if="['accounts'].includes(user.user_type)">
      <div class="mb-6 d-flex justify-space-between align-center">
        <div style="width: 300px">
          <v-text-field
            v-model="search"
            clearable
            outlined
            dense
            placeholder="Search name, email, or etc."
            prepend-inner-icon="mdi-magnify"
            color="primary"
            hide-details
            height="40px"
            class="rounded-lg"
          >
          </v-text-field>
        </div>
      </div>

      <!-- tabs -->
      <v-sheet elevation="3" class="rounded-b-0">
        <v-tabs
          next-icon="mdi-chevron-right-circle"
          prev-icon="mdi-chevron-left-circle"
          v-model="tab"
          show-arrows
        >
          <v-tab
            v-for="(item, index) in tabs"
            :key="index + item.key"
            class="tab-width"
          >
            <div class="tab-name text-capitalize">
              {{ item.label }}
            </div>
          </v-tab>
        </v-tabs>
      </v-sheet>
      <v-divider />
      <AccountsDataTable ref="dataTable" />
    </div>

    <div v-else>
      <div class="d-flex justify-end mb-4">
        <v-sheet
          color="primary pa-3 d-flex justify-space-between align-center"
          rounded="lg"
          style="gap: 20px"
        >
          <div class="white--text">
            <div class="text-caption">Current Balance</div>
            <div class="font-weight-bold text-h6">
              {{ user.ledger_balance }}
            </div>
          </div>
          <v-icon color="grey lighten-1" size="50">mdi-currency-rupee</v-icon>
        </v-sheet>
      </div>

      <DataTable ref="dataTable" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { accountsTabs } from "@/tabs/ledger";
import DataTable from "@/components/Ledger/DataTable";
import AccountsDataTable from "@/components/Ledger/AccountsDataTable";

export default {
  components: {
    DataTable,
    AccountsDataTable,
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      user: "getUser",
      activeTab: "ledger/activeTab",
      getSearch: "ledger/search",
    }),

    tab: {
      get() {
        return this.activeTab.index;
      },
      set(value) {
        this.setActiveTab({ index: value, key: this.tabs[value].key });
      },
    },

    tabs() {
      switch (this.user.user_type) {
        case this.$keys.ACCOUNTS:
          return accountsTabs;
        default:
          return [];
      }
    },

    search: {
      get() {
        return this.getSearch;
      },
      set(value) {
        this.setSearch(value);
      },
    },
  },

  mounted() {
    this.$root.$on("onNavClicked", this.navClickHandler);
  },

  beforeDestroy() {
    this.$root.$off("onNavClicked", this.navClickHandler);
  },

  methods: {
    ...mapActions({
      setActiveTab: "ledger/setActiveTab",
      setSearch: "ledger/setSearch",
    }),

    navClickHandler(navKey) {
      if (navKey === "ledger") {
        this.reloadData();
      }
    },

    reloadData() {
      if (this.$refs.dataTable) {
        this.$refs.dataTable.getLedgerList();
      }
    },
  },
};
</script>