var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-3 rounded-t-0",attrs:{"items-per-page":_vm.page_count,"server-items-length":_vm.page_count,"loading":_vm.loading,"loading-text":"Please wait.... Loading","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","mobile-breakpoint":""},scopedSlots:_vm._u([{key:"item.sn",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.contact",fn:function(ref){
var item = ref.item;
return [(item.user.mobile || item.user.email || item.full_address)?_c('div',[(item.user.mobile)?_c('div',[_vm._v(" "+_vm._s(item.user.mobile_display)+" ")]):_vm._e(),(item.user.email)?_c('div',[_vm._v(" "+_vm._s(item.user.email)+" ")]):_vm._e(),(item.full_address)?_c('div',[_vm._v(" "+_vm._s(item.full_address)+" ")]):_vm._e()]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [(item.role)?_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.role_display)+" ")]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [(item.user.ledger_balance)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.user.ledger_balance)+" ")]):_c('div',[_vm._v("0")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-4 d-flex justify-center",staticStyle:{"gap":"10px"}},[_c('v-btn',{staticClass:"text-capitalize rounded-lg",attrs:{"small":"","outlined":"","color":"primary","height":"32px"},on:{"click":function($event){return _vm.addNote(item)}}},[_vm._v(" Add Note ")]),_c('v-btn',{staticClass:"text-capitalize rounded-lg",attrs:{"small":"","outlined":"","color":"primary","height":"32px"},on:{"click":function($event){return _vm.openLedger(item)}}},[_vm._v(" View Ledger ")])],1)]}}],null,true)}),_c('v-container',{staticClass:"d-flex justify-space-between align-center px-0 mb-0 mt-4",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex align-center text-subtitle-2"},[_c('div',{staticClass:"mr-2"},[_vm._v("Rows per page")]),_c('div',{staticStyle:{"width":"75px"}},[_c('v-select',{staticClass:"rounded-lg",attrs:{"menu-props":{ bottom: true, offsetY: true },"items":_vm.itemsPerPageList,"hide-details":"","dense":"","solo":"","color":"primary"},model:{value:(_vm.page_count),callback:function ($$v) {_vm.page_count=$$v},expression:"page_count"}})],1),_c('div',{staticClass:"ml-6"},[_vm._v(" Results: "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageEnd)+" of "+_vm._s(_vm.total_count)+" ")])]),_c('v-pagination',{attrs:{"length":_vm.page_total,"total-visible":5,"color":"primary"},model:{value:(_vm.page_number),callback:function ($$v) {_vm.page_number=$$v},expression:"page_number"}})],1),_c('LedgerDialog'),_c('AddNoteDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }